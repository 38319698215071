.global-text {
  &.MuiTypography-root {
    &.text-align--left {
      display: flex;
      justify-content: flex-start;
    }

    &.text-align--center {
      display: flex;
      justify-content: center;
    }

    &.text-align--right {
      display: flex;
      justify-content: flex-end;
    }

    &.text-style__variant--headers {
      line-height: 1.1em;
      letter-spacing: -0.02em;

      &.text-style__font-weight--bold {
        &.header-small-font-weight {
          font-weight: 600;
        }
      }
    }

    &.text-style__variant--paragraphs {
      line-height: 1.4em;
    }

    &.text-style__variant--labels {
      line-height: 1em;

      &.text-style__font-weight--bold {
        &.label-small-font-weight {
          font-weight: 800;
        }
      }

      &.text-style__font-weight--regular {
        &.label-small-font-weight {
          font-weight: 500;
        }
      }
    }

    &.text-style__font-weight--bold {
      font-weight: 700;
    }

    &.text-style__font-weight--regular {
      font-weight: 400;
    }
  }
}
