.marker svg:hover {
  cursor: pointer;
}

.cluster-marker {
  font-size: 16px;
  color: #fff;
  background: #000;
  opacity: 50%;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cluster-marker--small {
  width: 32px;
  height: 32px;
}

.cluster-marker--medium {
  width: 65px;
  height: 65px;
}

.cluster-marker--large {
  width: 104px;
  height: 104px;
}
