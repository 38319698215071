.s,
.n,
.e,
.en,
.nw,
.w {
  position: relative;
}

.s::after,
.n::after,
.e::after,
.w::after,
.en::after,
.nw::after {
  position: absolute;
  content: "";
  background-color: inherit;
  width: 17px;
  height: 17px;
  transform: rotate(45deg);
}

.s::after,
.e::after,
.w::after {
  bottom: -10px;
}

.s::after,
.n::after {
  left: 47%;
}

.e::after {
  right: -12px;
}

.en::after {
  top: -14px;
  right: -14px;
  border-top-right-radius: 140px 13px;
}

.n::after {
  top: -15px;
}

.nw::after {
  top: -14px;
  left: -12px;
}

.w::after {
  left: -11px;
}

.gm-style-iw-tc {
  display: none;
}

.gm-style-iw-c {
  overflow: visible !important;
}

.gm-style-iw {
  outline: none;
}

/*
[PRJIND-7303]
This style enhances the hover effect on the clusters and markers on the map.
*/
.gm-style div[role="button"] {
  border-radius: 50%;
}
