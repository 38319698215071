.global-text-btn {
  &.MuiButton-root {
    font-weight: 800;
    text-transform: inherit;

    &:disabled {
      opacity: 0.4;
    }

    &:hover {
      text-decoration: underline;
      background-color: inherit;
    }

    svg {
      font-size: inherit;
    }

    &.global-text-btn--small {
      height: 24px;
      padding: 0 4px;
      font-size: 10px;
    }

    &.global-text-btn--medium {
      height: 32px;
      padding: 0 8px;
      font-size: 16px;
    }

    &.global-text-btn--large {
      padding: 0 16px;
      height: 48px;
      font-size: 18px;
    }

    &.global-text-btn__theme--blue {
      color: var(--brand) !important;
    }

    &.global-text-btn__theme--black {
      color: #242424;
    }

    &.global-text-btn__theme--white {
      color: #fff;
    }
  }
}
