.slide-pane__overlay {
  z-index: 1205;
  .slide-pane {
    box-shadow: 0px 4px 10px 0px var(--box-shadow);
    .slide-pane__content {
      padding: 0px;
      height: 100vh;
      overflow: hidden;
      .slide-pane__button_panel {
        position: fixed;
        bottom: 12px;
        right: 0;
        left: 0;
      }
    }

    .slide-pane__header {
      .slide-pane__close svg {
        width: 24px;
      }
    }
  }
}
