@import "fonts";
@import "base/index";
@import "components/index";
@import "layout/index";

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-calendar-picker-indicator {
  background-color: white;
}
