.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-group button {
  @apply flex items-center justify-center bg-secondary;
}
.mapboxgl-ctrl-icon {
  @apply bg-typography #{!important};
}
.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  width: 14px !important;
  height: 14px !important;
  mask: url("~/src/assets/svgs/plusIcon.svg") no-repeat !important;
  background-image: none !important;
}

.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  width: 14px !important;
  height: 2px !important;
  background-image: none !important;
  mask: url("~/src/assets/svgs/minusIcon.svg") no-repeat !important;
}

// Changes the icon and the color of open map fullscreen button, based ot the theme.
.light .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-color: var(--background) !important;
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBzdHJva2U9JyMwMDAnIGZpbGw9JyMwMDAnIHZpZXdCb3g9IjAgMCAzMiAzMiI+Cjx0aXRsZT5mdWxsc2NyZWVuPC90aXRsZT4KPHBhdGggZD0iTTI3LjQxNCAyNC41ODZsLTQuNTg2LTQuNTg2LTIuODI4IDIuODI4IDQuNTg2IDQuNTg2LTQuNTg2IDQuNTg2aDEydi0xMnpNMTIgMGgtMTJ2MTJsNC41ODYtNC41ODYgNC41NDMgNC41MzkgMi44MjgtMi44MjgtNC41NDMtNC41Mzl6TTEyIDIyLjgyOGwtMi44MjgtMi44MjgtNC41ODYgNC41ODYtNC41ODYtNC41ODZ2MTJoMTJsLTQuNTg2LTQuNTg2ek0zMiAwaC0xMmw0LjU4NiA0LjU4Ni00LjU0MyA0LjUzOSAyLjgyOCAyLjgyOCA0LjU0My00LjUzOSA0LjU4NiA0LjU4NnoiPjwvcGF0aD4KPC9zdmc+Cg==");
}
.dark .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-color: var(--background) !important;
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBzdHJva2U9JyNmZmYnIGZpbGw9JyNmZmYnIHZpZXdCb3g9IjAgMCAzMiAzMiI+Cjx0aXRsZT5mdWxsc2NyZWVuPC90aXRsZT4KPHBhdGggZD0iTTI3LjQxNCAyNC41ODZsLTQuNTg2LTQuNTg2LTIuODI4IDIuODI4IDQuNTg2IDQuNTg2LTQuNTg2IDQuNTg2aDEydi0xMnpNMTIgMGgtMTJ2MTJsNC41ODYtNC41ODYgNC41NDMgNC41MzkgMi44MjgtMi44MjgtNC41NDMtNC41Mzl6TTEyIDIyLjgyOGwtMi44MjgtMi44MjgtNC41ODYgNC41ODYtNC41ODYtNC41ODZ2MTJoMTJsLTQuNTg2LTQuNTg2ek0zMiAwaC0xMmw0LjU4NiA0LjU4Ni00LjU0MyA0LjUzOSAyLjgyOCAyLjgyOCA0LjU0My00LjUzOSA0LjU4NiA0LjU4NnoiPjwvcGF0aD4KPC9zdmc+Cg==");
}

// Changes the icon and the color of exit map fullscreen button, based ot the theme.
.light .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
  background-color: var(--background) !important ;
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBzdHJva2U9JyMwMDAnIGZpbGw9JyMwMDAnIHZpZXdCb3g9IjAgMCAzMiAzMiI+Cjx0aXRsZT5mdWxsc2NyZWVuLWV4aXQ8L3RpdGxlPgo8cGF0aCBkPSJNMjQuNTg2IDI3LjQxNGw0LjU4NiA0LjU4NiAyLjgyOC0yLjgyOC00LjU4Ni00LjU4NiA0LjU4Ni00LjU4NmgtMTJ2MTJ6TTAgMTJoMTJ2LTEybC00LjU4NiA0LjU4Ni00LjUzOS00LjU0My0yLjgyOCAyLjgyOCA0LjUzOSA0LjU0M3pNMCAyOS4xNzJsMi44MjggMi44MjggNC41ODYtNC41ODYgNC41ODYgNC41ODZ2LTEyaC0xMmw0LjU4NiA0LjU4NnpNMjAgMTJoMTJsLTQuNTg2LTQuNTg2IDQuNTQ3LTQuNTQzLTIuODI4LTIuODI4LTQuNTQ3IDQuNTQzLTQuNTg2LTQuNTg2eiI+PC9wYXRoPgo8L3N2Zz4K");
}

.dark .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
  background-color: var(--background) !important ;
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBzdHJva2U9JyNmZmYnIGZpbGw9JyNmZmYnIHZpZXdCb3g9IjAgMCAzMiAzMiI+Cjx0aXRsZT5mdWxsc2NyZWVuLWV4aXQ8L3RpdGxlPgo8cGF0aCBkPSJNMjQuNTg2IDI3LjQxNGw0LjU4NiA0LjU4NiAyLjgyOC0yLjgyOC00LjU4Ni00LjU4NiA0LjU4Ni00LjU4NmgtMTJ2MTJ6TTAgMTJoMTJ2LTEybC00LjU4NiA0LjU4Ni00LjUzOS00LjU0My0yLjgyOCAyLjgyOCA0LjUzOSA0LjU0M3pNMCAyOS4xNzJsMi44MjggMi44MjggNC41ODYtNC41ODYgNC41ODYgNC41ODZ2LTEyaC0xMmw0LjU4NiA0LjU4NnpNMjAgMTJoMTJsLTQuNTg2LTQuNTg2IDQuNTQ3LTQuNTQzLTIuODI4LTIuODI4LTQuNTQ3IDQuNTQzLTQuNTg2LTQuNTg2eiI+PC9wYXRoPgo8L3N2Zz4K");
}

.mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
  bottom: 0.5rem;
}

.mapboxgl-ctrl-zoom-in:hover,
.mapboxgl-ctrl-zoom-out:hover {
  @apply bg-secondary #{!important};
}

.mapboxgl-ctrl-bottom-left {
  display: none;
}

.mapboxgl-compact,
.mapboxgl-ctrl-attrib-inner {
  display: none;
}
// set theme bg color to popup arrow.
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--custom-tooltip-background) !important;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--custom-tooltip-background) !important;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: var(--custom-tooltip-background) !important;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: var(--custom-tooltip-background) !important;
}

.text-limit-asset-location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.mapWidgetContainer {
  min-height: 312px;
}

// <---- Used for animation of the 3 dots in the MapComponent loading text ----->
.loading-text text {
  animation: 1s blink steps(5, end) infinite;
  color: black;
}
.loading-text text:nth-child(2) {
  animation-delay: 250ms;
}
.loading-text text:nth-child(3) {
  animation-delay: 500ms;
}

@keyframes blink {
  30% {
    color: #9f9f9f;
  }
  50% {
    color: #ffffff;
  }
}

// google map
.gm-ui-hover-effect {
  display: none !important;
}
