#root {
  margin: 0;
  font-weight: 700;
  height: 100%;
}

#pcMap {
  z-index: 0;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
}

a {
  text-decoration: none; // Disable a tag underline by default
}

select {
  option {
    font-family: Montserrat, sans-serif; // fix for Firefox
  }
}
