.asset-tooltip {
  .asset-detail-popover {
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 175px;
    z-index: 1;
    border-radius: 10px;
  }

  .bottom-pad {
    padding-bottom: 10px;
  }

  .sub-heading {
    font-weight: 500;
    font-size: 10px;
    border-bottom: 1px solid #e3e3e3;
    color: #9c9c9c;
    text-align: center;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading p {
    margin: 0;
  }

  .tyre-pressure-monitoring {
    display: flex;
    width: 100%;
    padding-top: 10px;
  }

  .col {
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .col.col-5 {
    flex: 1 0 35%;
    padding: 5px;
  }

  .col-item {
    flex: 1 0 35%;
  }

  .details-container,
  .other-detail-severity-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .other-detail-head {
    color: #9c9c9c;
    font-size: 10px;
    gap: 5px;
  }

  .asset-details-tooltip-wrapper {
    background-color: #fff;
  }

  .detail-severity-text {
    width: 60px;
    font-size: 8px;
    color: #ffffff;
    padding: 2px 4px;
    display: flex;
    justify-content: center;
    border-radius: 4px;

    &.severity--error {
      background-color: #ca1212;
    }

    &.severity--alert {
      background-color: #e78d3a;
    }

    &.severity--warning {
      background-color: #e5be33;
    }

    &.severity--healthy {
      background-color: #339933;
    }
  }

  .detail-severity-text p {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e3e3e3;
    padding: 8px;
    padding-bottom: 0px;
    color: #9c9c9c;
  }

  .asset-head {
    text-decoration: underline;
    font-weight: 700;
    font-size: 10px;
    color: #464646;
  }

  .tyre-status {
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tyre-status.tyre-status--healthy {
    color: #339933;
    border: 2px solid #339933;
  }

  .tyre-status.tyre-status--warning {
    color: #e5be33;
    border: 2px solid #e5be33;
  }

  .tyre-status.tyre-status--alert {
    color: #e78d3a;
    border: 2px solid #e78d3a;
  }

  .tyre-status.tyre-status--open {
    color: #ca1212;
    border: 2px solid #ca1212;
  }

  .healthy {
    width: 16px;
    height: 32px;
    border-radius: 4px;
    color: #339933;
    border: 1px solid #339933;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .button-icon {
    width: 12px;
    height: 16px;
    background: #5086c7;
    border: 2px solid #426fa6;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 4px;
    gap: 2px;
    color: #fff;
  }

  .footer {
    display: flex;
    align-items: center;
    .icon {
      display: flex;
      gap: 6px;
    }
  }

  .icon-head {
    color: #ca1212;
  }

  .other-detail-head {
    display: flex;
  }

  .asset-eta {
    background-color: #339933;
    align-items: center;
    padding: 2px 4px;
    gap: 2px;
    border-radius: 4px;
    font-size: 8px;
  }

  .asset-eta p {
    gap: 3px;
  }

  .avatar-icon {
    height: 26px !important;
    width: 26px !important;
  }

  .x-y-center {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
  }

  .tyre-profile {
    width: 100%;
    color: #9c9c9c;
    border-bottom: 1px solid #9c9c9c;
    text-align: center;
  }

  .top-pad {
    padding-top: 8px;
  }

  .subhead {
    font-weight: 500;
    font-size: 10px;
    color: #9c9c9c;
  }
}
