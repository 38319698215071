.global-btn {
  &.MuiButton-root {
    font-weight: 800;
    text-transform: inherit;
    border-radius: 48px;
    padding: 0 16px;

    &:disabled {
      background: #dfdfdf !important;
      color: #808080 !important;
    }

    svg {
      font-size: inherit;
    }

    &.global-btn--small {
      height: 32px;
      font-size: 10px;
    }

    &.global-btn--medium {
      height: 48px;
      padding: 0 24px;
      font-size: 16px;
    }

    &.global-btn--large {
      padding: 0 32px;
      height: 64px;
      font-size: 18px;
    }

    &.global-btn__variant--default {
      &.global-btn__theme--blue {
        background-color: var(--brand);
        color: #fff;
      }

      &.global-btn__theme--red {
        background-color: transparent;
        color: #ca1212;
      }

      &.global-btn__theme--black {
        background-color: #242424;
        color: #fff;
      }

      &.global-btn__theme--white {
        background-color: #fff;
        color: #242424;
      }
    }

    &.global-btn__variant--ghost {
      &.global-btn__theme--blue {
        border: 1px solid #3c88cc;
        color: #3c88cc;
      }

      &.global-btn__theme--black {
        border: 1px solid #242424;
        color: #242424;
      }

      &.global-btn__theme--white {
        border: 1px solid #fff;
        color: #fff;
      }
    }
  }
}
