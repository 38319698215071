.terms-container {
  max-width: 700px;
  margin: 0 auto;
  padding: 0px 4px;
}

.pdf-container {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  border: 1px solid gainsboro;
  border-radius: 5px;
}

.pdf-container:hover {
  box-shadow: 1px 1px 4px #ccc;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.react-pdf__Page__textContent span {
  opacity: 0.1;
}

.react-pdf__Page__textContent span::selection {
  background-color: blue;
}

.react-pdf__Document {
  line-height: initial;
}
