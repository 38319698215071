.gm-style-iw-c {
  background-color: var(--background) !important;
  padding: 0 18px 18px !important;
  height: auto;
  button {
    span {
      background-color: var(--typography) !important;
    }
  }
}
.gm-style-iw-d {
  height: auto;
  background-color: var(--background) !important;
  overflow: unset !important;
}

.gm-style-iw-tc {
  &::after {
    background-color: var(--background) !important;
  }
}
