.assets-filter-date-range-picker {
  &.MuiPickerStaticWrapper-root {
    .MuiPickerStaticWrapper-content {
      min-width: auto;
    }
  }
  .MuiDayPicker-weekContainer {
    @apply m-0;
  }

  .MuiDateRangePickerDay-root {
    @apply m-0;
  }

  .MuiDateRangePickerDay-day {
    @apply text-primary;
  }

  .PrivatePickersSlideTransition-root {
    &.MuiDayPicker-slideTransition {
      min-height: 226px !important;
    }
  }
  .MuiDateRangePickerViewDesktop-container {
    @apply m-auto;
  }

  .MuiDayPicker-weekDayLabel {
    @apply font-bold leading-none text-brand-light-blue;
  }

  .MuiDateRangePickerDay-rangeIntervalDayHighlight {
    @apply bg-date-range-interval-highlight;

    &:not(.MuiDateRangePickerDay-rangeIntervalDayHighlightStart):not(
        .MuiDateRangePickerDay-rangeIntervalDayHighlightEnd
      ) {
      &:first-of-type,
      &:last-of-type {
        @apply rounded-none;
      }
    }

    .MuiDateRangePickerDay-day {
      &.Mui-selected {
        @apply bg-brand;

        &:focus,
        &:hover {
          @apply text-white;
        }
      }

      &.MuiDateRangePickerDay-notSelectedDate:not(.MuiPickersDay-today) {
        @apply rounded-none;
      }
    }
  }

  .MuiDateRangePickerDay-rangeIntervalPreview {
    .MuiPickersDay-dayOutsideMonth {
      @apply text-mid-charcoal;

      &.MuiDateRangePickerDay-dayInsideRangeInterval {
        @apply bg-date-range-inside-interval;
      }

      &:focus,
      &:hover {
        @apply border border-solid border-primary;
      }

      &.Mui-selected {
        @apply bg-brand;
      }
    }

    .MuiDateRangePickerDay-dayOutsideRangeInterval {
      &.Mui-selected {
        @apply bg-brand;

        &:hover,
        &:focus {
          @apply border-none;
        }
      }
    }
  }
}

.trending-voltage-date-picker {
  .Mui-selected {
    &:focus,
    &:hover {
      color: var(--primary) !important;
    }
  }
  .MuiPickersToolbar-penIconButton {
    display: none !important;
  }
  .MuiPaper-root {
    @apply bg-dashboard_subheader__bg;
    background-image: none;
  }
}

.add-asset-form-container {
  .Mui-selected {
    &:focus,
    &:hover {
      color: var(--primary) !important;
    }
  }
}

.MuiPickersDay-root.Mui-selected {
  @apply bg-datepicker-selected #{!important};
}

.MuiPickersDay-root:hover {
  @apply bg-datepicker-hover-bg #{!important};
  @apply border-typography-secondary #{!important};
  border-style: solid;
  border-width: 1px;
}

.MuiPickersPopper-root {
  .MuiPickersPopper-paper {
    @apply bg-dashboard_subheader__bg #{!important};
    background-image: none;
  }
}

.MuiPickersDay-root {
  background-color: unset !important;
}
