.MuiAutocomplete-option {
  &:hover {
    background-color: var(--brand-transparent) !important;
  }
  &[aria-selected="true"] {
    background-color: var(--brand) !important;
    color: white;
    &:hover {
      background-color: var(--brand) !important;
      color: white;
    }
    &.Mui-focused:hover {
      background-color: var(--brand) !important;
      color: white;
    }
  }
}

.MuiAutocomplete-listbox > div > div:first-child {
  color: var(--brand) !important;
}
