/*** overwrite query builder styles ***/

.query-builder-container {
  .group {
    border: 1px solid #808080 !important;
    border-radius: 16px;
  }

  .rule {
    background-color: transparent !important;
    border: 1px solid #808080 !important;
    border-radius: 16px;
  }

  .group--header:not(.no--children):not(.hide--conjs)::before,
  .group--children > .group-or-rule-container > .group-or-rule::before,
  .group--children > .group-or-rule-container > .group-or-rule::after {
    border-color: transparent !important;
  }
}
