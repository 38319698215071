.spinner {
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .svg {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 6em;
    height: 6em;
    margin-bottom: 1em;

    & .svgpath {
      stroke: $white;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}
