.snackbar {
  cursor: pointer;
  user-select: none;
  z-index: 9999;
  box-sizing: border-box;
  top: 1.5em;

  .fullwidth {
    width: calc(100vw - 4em);
  }
}
