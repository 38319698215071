.div_fullscreen {
  width: 100vw;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;
}

.disabled {
  cursor: not-allowed;
  user-select: none;
}

.darken_fullscreen {
  @extend .div_fullscreen;
  @extend .disabled;
  background: $dark_transparent;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
}
