.dashboard-frame-tabs {
  @apply h-8 min-h-0 #{!important};

  button {
    @apply h-8 min-h-0 min-w-0 px-2 py-2.5 font-normal text-checkbox-unchecked #{!important};

    &.Mui-selected {
      @apply font-bold #{!important};
    }

    &.Mui-disabled {
      @apply opacity-60 #{!important};
    }
  }

  .MuiTabs-indicator {
    @apply bg-brand #{!important};
  }
}
